
import React, { useEffect, useState } from 'react'
import { Container, Spinner, Table } from "react-bootstrap";
import Layout from '../components/Layout'
import allApiData from "../api";
import SeoheadData from '../siteScriptData';
import Seo from '../components/Seo';

function Resourceinfo() {

    let [resourceData, setResourceData] = useState([]);
    let [lastUpdated, setLastUpdated] = useState("");
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        allApiData.aosinit()
    }, []);

    useEffect(() => {
        allApiData.resource().then((res) => {
            setResourceData(res.data);
            setLastUpdated(res.last_updated);
        })
    }, [])

    useEffect(() => {
        if (resourceData && resourceData.length > 0) {
            setLoading(false)
        } else {
            setLoading(true)
        }
    }, [resourceData])

    return (
        <Layout>
            <Seo SeoData={SeoheadData.resourcePageSeoData}></Seo>
            <Container className='mb-5 pb-5 mt-sm-5 mt-4 resources'>
                <div className="heading ">
                    <h2>Resource Availability</h2>
                </div>

                <div className="user-r mb-sm-4 mb-sm-5 pb-50 mt-sm-5 mt-4">
                    <div className="user_data_table">

                        <Table className='mb-0' responsive>
                            <thead>
                                <tr>
                                    <th>platform</th>
                                    <th>experience<br className='d-block d-sm-none' />(years)</th>
                                    <th> available <br className='d-block d-md-none' /> Resources</th>
                                </tr>
                            </thead>

                            <tbody>
                                {
                                    loading ?
                                        <tr>
                                            <th colSpan={3}>
                                                <span className="d-flex justify-content-center py-5">
                                                    <Spinner animation="border" variant="primary" />
                                                </span>
                                            </th>
                                        </tr>
                                        :
                                        <>
                                            {
                                                resourceData.map((_data) => {
                                                    return (
                                                        <tr>
                                                            <td>
                                                                <div className={`${_data.experience && _data.experience.length >= 2 ? `${_data.experience.length > 2 ? `p-add` : ``}` : `p-remove`}`}>{_data.platform}</div>
                                                            </td>
                                                            <td>
                                                                {
                                                                    Object.entries(_data.availability).map(([key, value], i) => <div key={i} value={key}>{key}</div>)
                                                                }
                                                            </td>
                                                            <td>
                                                                {
                                                                    Object.entries(_data.availability).map(([key, value], i) => <div key={i} value={key}>{value}</div>)
                                                                }
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </>
                                }

                                <div>
                                    {/* <tr>
                                        <td>
                                            <div>Android (Native)</div>
                                        </td>
                                        <td>
                                            <div>0 - 2</div>
                                            <div>4 - 5</div>
                                        </td>
                                        <td>
                                            <div>1</div>
                                            <div>2</div>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>
                                            <div>
                                                Unity
                                            </div>
                                        </td>
                                        <td>
                                            <div>0 - 2</div>
                                            <div>3 - 4</div>
                                        </td>
                                        <td>
                                            <div>9</div>
                                            <div>1</div>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>
                                            <div>
                                                3D Modeller
                                            </div>
                                        </td>
                                        <td>
                                            <div>0 - 2</div>
                                            <div>3 - 4</div>
                                        </td>
                                        <td>
                                            <div>12</div>
                                            <div>1</div>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>
                                            <div>
                                                3D Animatore
                                            </div>
                                        </td>
                                        <td>
                                            <div>0 - 2</div>
                                            <div>2 - 3</div>
                                        </td>
                                        <td>
                                            <div>5</div>
                                            <div>1</div>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>
                                            <div className='p-add'>
                                                UI/UX Designer
                                            </div>
                                        </td>
                                        <td>
                                            <div>0 - 2</div>
                                            <div>2 - 3</div>
                                            <div>3 - 4</div>
                                        </td>
                                        <td>
                                            <div>2</div>
                                            <div>1</div>
                                            <div>2</div>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>
                                            <div className='p-remove'>
                                                Flutter
                                            </div>
                                        </td>
                                        <td>
                                            <div>2 - 3</div>
                                        </td>
                                        <td>
                                            <div>2</div>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>
                                            <div>
                                                React Native
                                            </div>
                                        </td>
                                        <td>
                                            <div>0 - 2</div>
                                            <div>2 - 3</div>
                                        </td>
                                        <td>
                                            <div>2</div>
                                            <div>1</div>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>
                                            <div>
                                                Python
                                            </div>
                                        </td>
                                        <td>
                                            <div>0 - 2</div>
                                            <div>3 - 4</div>
                                        </td>
                                        <td>
                                            <div>1</div>
                                            <div>1</div>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>
                                            <div className='p-remove'>
                                                MERN/MEAN
                                            </div>
                                        </td>
                                        <td>
                                            <div>0 - 2</div>
                                        </td>
                                        <td>
                                            <div>4</div>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>
                                            <div className='p-remove'>
                                                QA/QC
                                            </div>
                                        </td>
                                        <td>
                                            <div>0 - 2</div>
                                        </td>
                                        <td>
                                            <div>3</div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className='p-remove'>
                                                Node JS
                                            </div>
                                        </td>
                                        <td>
                                            <div>0 - 2</div>
                                        </td>
                                        <td>
                                            <div>5</div>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>
                                            <div className='p-remove'>
                                                React JS
                                            </div>
                                        </td>
                                        <td>
                                            <div>0 - 2</div>
                                        </td>
                                        <td>
                                            <div>3</div>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>
                                            <div>
                                                Shopify/Wordpress
                                            </div>
                                        </td>
                                        <td>
                                            <div>0 - 2</div>
                                            <div>2 - 3</div>
                                        </td>
                                        <td>
                                            <div>3</div>
                                            <div>1</div>
                                        </td>
                                    </tr> */}
                                </div>

                            </tbody>
                        </Table>
                    </div>
                    {
                        lastUpdated &&
                        <div className='d-flex justify-content-end mt-4'>
                            <span className="hero-btn">Last Updated: {lastUpdated ? lastUpdated : "24/04/2022"}</span>
                        </div>

                    }
                </div>

            </Container>
        </Layout>
    )
}

export default Resourceinfo